<template>
      <div class="companyVehicleEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'companyVehicle', params: {} }">
          Company Vehicle
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'companyVehicleEdit', params:{id: id} }">
            Edit Company Vehicle
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Company Vehicle</v-card-title>
          <v-card-text>
            <companyVehicle-form :id="id"></companyVehicle-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import CompanyVehicleForm from './CompanyVehicleForm.vue';

    export default {
      name: 'companyVehicle',
      data () {
        return {
          id: null
        }
      },
      components: {
        CompanyVehicleForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    